<template>
  <b-card title="Thêm mới: Thông tin banner">
    <b-form @submit.prevent="handleSubmit()">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Tiêu đề"
            label-cols-md="2"
            label-for="bannerData-title"
          >
            <b-form-input
              id="bannerData-title"
              v-model="bannerData.title"
              placeholder="Vú sữa giảm giá 40%"
              :class="{ 'is-invalid' : isInvalid(bannerData.title)}"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Link sản phẩm"
            label-cols-md="2"
            label-for="bannerData-url"
          >
            <b-form-input
              id="bannerData-url"
              v-model="bannerData.url"
              placeholder="https://shoppee.vn/...."
              :class="{ 'is-invalid' : isInvalid(bannerData.url)}"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Ảnh banner"
            label-cols-md="2"
            label-for="bannerData-banner"
          >
            <b-form-input
              id="bannerData-banner"
              v-model="bannerData.banner"
              placeholder="https://shoppee.vn/...."
              :class="{ 'is-invalid' : isInvalid(bannerData.banner)}"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" v-if="bannerData.banner">
          <b-form-group
              label="Xem trước hình ảnh"
              label-cols-md="2"
              label-for="h-image-preview "
          >
            <b-img class="preview-image" :src="bannerData.banner" v-viewer />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mr-1"
            type="submit"
            variant="primary"
          >
            Lưu thông tin
          </b-button>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput, BImg,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "../../../../@core/components/toastification/ToastificationContent";
export default {
  name: "BannerAdd.vue",
  directives: {
    Ripple
  },
  data() {
    return {
      isValidate: false,
      bannerData: {
        index: 0,
        title: '',
        url: '',
        banner: ''
      },
    }
  },
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BImg
  },
  methods: {
    isInvalid(val) {
      return this.isValidate && val.length === 0
    },
    handleSubmit () {
      this.isValidate = true
      if (
          this.bannerData.title.length &&
          this.bannerData.url.length &&
          this.bannerData.banner.length)
      {
        this.handleRequest()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Thông báo',
            icon: 'BellIcon',
            text: 'Vui lòng nhập đầy đủ thông tin!',
            variant: 'danger',
          },
        })
      }
    },
    async handleRequest() {
      this.$http.post(`/banners`, this.bannerData)
        .then(response => {
          console.log(response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              icon: 'BellIcon',
              text: 'Cập nhật thành công👋',
              variant: 'success',
            },
          })
          this.$router.push('/manage/banner/list')
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              icon: 'BellIcon',
              text: 'Đã xảy ra lỗi. Vui lòng thử lại sau!',
              variant: 'danger',
            },
          })
        })
    }
  }
}
</script>

<style scoped>

</style>
